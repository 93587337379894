import Link from "next/link";

import { cn } from "@/libs/utils";

type CountryBadgeProps = {
	className?: string;
	flag?: null | string;
	showMobileCountry?: boolean;
	title?: null | string;
};

const CountryBadge: React.FC<CountryBadgeProps> = ({ flag, title, className, showMobileCountry }) => {
	if (!flag || !title) return null;
	return (
		<Link href={`/${flag}`} className="">
			<div
				className={cn(
					"bg-primaryBlack/50 backdrop-blur-sm rounded-full text-white text-sm flex items-center w-max py-1 pl-2 pr-3 hover:bg-primaryBlack transition duration-500 sm:p-1",
					className
				)}
			>
				<img
					src={`/static/flags/${flag}.svg`}
					alt={flag}
					className="w-4 h-4 rounded-full object-cover mr-[6px] sm:mr-0"
				/>
				<span className={cn(showMobileCountry ? "ml-1" : "sm:hidden")}>{title}</span>
			</div>
		</Link>
	);
};

export default CountryBadge;
