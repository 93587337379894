import { useState } from "react";

import { XPlitkaIcon } from "@RDN794312/xplitka-icons";

import { cn } from "@/libs/utils";
import { ThemeColor } from "@/types";

type AddFavoriteButtonProps = {
	className?: string;
	isFavorite?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const AddFavoriteButton: React.FC<AddFavoriteButtonProps> = ({ className, isFavorite, ...restProps }) => {
	const [iconColor, setIconColor] = useState(isFavorite ? ThemeColor.accentGreen : ThemeColor.white);
	const iconName = isFavorite ? "favoriteFilled" : "favoriteStroke";
	return (
		<button
			className={cn("bg-primaryBlack/50 backdrop-blur-sm px-4 py-3 rounded-bl-3xl", className)}
			type="button"
			name="favorite"
			aria-label="Добавить в избранное"
			onMouseEnter={() => {
				if (isFavorite) return;
				setIconColor(ThemeColor.accentGreen);
			}}
			onMouseLeave={() => {
				if (isFavorite) return;
				setIconColor(ThemeColor.white);
			}}
			{...restProps}
		>
			<XPlitkaIcon name={iconName} color={iconColor} size={24} />
		</button>
	);
};

export default AddFavoriteButton;
