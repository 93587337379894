import type { ItemPropertyResponse, ItemResponse } from "@/data/__generated__/types/generated";
import type { StoreState } from "@/hooks/useStore";

type ProductCardActionsArgs = {
	price?: null | number;
	product: ItemResponse;
	properties?: ItemPropertyResponse[];
	store: StoreState;
};

const ProductCardActions = ({ properties = [], price, store, product }: ProductCardActionsArgs) => {
	const propertyMap = new Map(properties.map(({ typeProperty, value }) => [typeProperty, value]));

	const size =
		propertyMap.get("Ширина") && propertyMap.get("Длина")
			? `${Number(propertyMap.get("Ширина")) / 100}x${Number(propertyMap.get("Длина")) / 100} cm`
			: null;

	const filteredProperties =
		[
			size ? { name: "Размер", value: size } : null,
			propertyMap.get("Поверхность") ? { name: "Поверхность", value: propertyMap.get("Поверхность") } : null,
			propertyMap.get("Цвет") ? { name: "Цвет", value: propertyMap.get("Цвет") } : null,
		].filter(Boolean) || [];

	const formatPrice = () => {
		if (!price) return "";

		return (price / 100)?.toLocaleString("en-US")?.replace(",", " ");
	};

	const handleAddToCart = () => {
		store.addItem({ data: product, orderBlock: product.order.orderAmounts });
	};

	return { filteredProperties, formatPrice, handleAddToCart };
};

export default ProductCardActions;
